<template>
  <div class="bg-primary min-h-screen flex flex-col">
    <NavBar />
    <div
      class="
        max-w-sm
        mx-auto
        flex-1 flex flex-col
        items-center
        justify-center
        px-2
      "
    >
      <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
        <h1 class="mb-8 text-3xl text-center">Sign In to Premium Tutors</h1>
        
        <v-text-field
        v-model="user.email"
          type="email"
          label="Email"
          placeholder="Email"
          outlined
          dense
        ></v-text-field>
        <v-text-field
        v-model="user.password"
          type="password"
          label="Password"
          placeholder="Password"
          outlined
          dense
        ></v-text-field>
        <ul  class="pb-3" v-for="error in errors" :key="errors[error]">
                <li><small class="text-red">
                  {{error}}
                </small>
                </li>
              </ul>

        <v-btn
        @click="handleLogin()"
          type="submit"
          color="teal"
          dark
          :loading="buttonLoader"
          class="
          loading
            w-full
            text-center
            py-3
            rounded
            bg-yellow-fade
            text-black
            hover:bg-green-dark
            focus:outline-none
            my-1
          "
        >
          Continue
        </v-btn>

        <div class="text-center text-sm text-grey-dark mt-4">
          By signing up, you agree to the
          <a
            class="no-underline border-b border-grey-dark text-grey-dark"
            href="/privacy"
          >
          <b>Terms of Service and Privacy Policy</b>
            
          </a>
          
        </div>
      </div>

      <div class="text-grey-dark mt-6">
        Forgot your password?
        <a class="no-underline border-b border-yellow text-yellow-fade" href="/auth/reset">
          <b>Recover</b> </a
        >.
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/Navbar.vue";
import DataService from "@/authentication/data.service";
export default {
  components: {
    NavBar,
  },
  setup() {},
  data(){
    return{
      errors:[],
      buttonLoader:false,
      user:{
        
        email:"",
        password:""
      },
      
  }},
   
   mounted(){
     if (this.$store.state.auth.user){
        DataService.getAuthUser().then(
              (response) => {
                const user = response.data;
                this.$router.push('/admin/profile/'+user.pk);

              }
              
            );
     }
   },
  
  
   methods: {
    handleLogin() {
      this.buttonLoader = true;
      
        
        if (this.user.email && this.user.password) {
       
          this.$store.dispatch('auth/login', this.user).then(
            async () => {
              
             await DataService.getAuthUser().then(
              (response) => {
                const user = response.data;
                this.$router.push('/admin/profile/'+user.pk);

              }
              
            );
               this.buttonLoader = false;
            },
            error => {
             const errors =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
              if (typeof(errors) === 'string'){
                this.errors = {"errors":errors}
              }else{
                this.errors = errors
              }
            this.buttonLoader = false;
            }
          );
        }
     
    }
  }
};
</script>

<style scoped>
a{
  color:black
}
</style>
